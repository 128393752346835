import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { motion, useAnimation } from 'framer-motion'
import useInView from 'react-cool-inview'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        paddingBottom: theme.spacing(2),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    button: {
        width: 250,
        alignSelf: 'center'
    }
}))

const ServiceItem = ({ name, img, text, animated, bolded }) => {
    const classes = useStyles();
    const controls = useAnimation()

    const { ref } = useInView({
        onEnter: () => {
            controls.start('open')
        },
        rootMargin: '-20px 0px'
    })

    const variants = {
        closed: {
            x: 100,
            opacity: 0
        },
        open: {
            x: 0,
            opacity: 1
        }
    }


    return (
        <>
            <motion.div className={classes.root} ref={ref} variants={variants} initial={animated ? 'closed' : 'open'} animate={animated ? controls : null} transition={{ duration: .7, delay: .3, type: 'spring', damping: 9 }}>
                <div>
                    <img src={img} style={{ minHeight: 100 }} alt="" />
                </div>
                <Typography gutterBottom variant="h5">{name}</Typography>
                <Typography gutterBottom variant="body2">{text} <span style={{ color: 'rgb(34,134,127)' }}><i><b>{bolded}</b></i></span> </Typography>
                <Button className={classes.button}>Learn More</Button>
            </motion.div>
        </>
    )
}
export default ServiceItem
