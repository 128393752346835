import WebDevelopment from '../../assets/images/icon-images/profile.png'
import Rebuild from '../../assets/images/icon-images/redesign.png'
import Maintenance from '../../assets/images/icon-images/software.png'
import SEO from '../../assets/images/icon-images/seo.png'
import WebHosting from '../../assets/images/icon-images/hosting.png'
import ContentCreation from '../../assets/images/icon-images/charts.png'

export const services = [
    {
        name: 'Web Development',
        text: 'Our Webfluent development specialists capitalize the latest in talent and technology to give your website',
        bolded: 'speed and style.',
        img: WebDevelopment
    },
    {
        name: 'Rebuild/ Redesign',
        text: 'We learn your business model and modernize your website into an attractive, efficient, and ',
        bolded: 'user-friendly experience.',
        img: Rebuild
    },
    {
        name: 'Maitenance and Managment',
        text: 'Change is a constant in technology. Our staff will keep your website operating at',
        bolded: 'peak performance 24/7.',
        img: Maintenance
    },
    {
        name: 'Search Engine Optimization',
        text: 'We engineer our websites to bring the maximum volume of traffic into ',
        bolded: 'your platform.',
        img: SEO
    },
    {
        name: 'Web Hosting',
        text: 'Let us manage your successful domain registration and launch. We will deploy the site and actively ',
        bolded: 'monitor new activity.',
        img: WebHosting
    },
]